import * as React from "react"
import styled from 'styled-components/macro'
import Title from "../../common/Title";
import Subtitle from "../../common/Subtitle";
import WhitepaperImage from '../../images/dolomite-whitepaper-image.svg'

const WhitepaperSectionWrapper = styled.div`
  width: 100%;
  color: #f9f9f9;
  position: relative;
  
  > div {
    width: 100%;
    text-align: center;
  }
  
  @media screen and (max-width: 880px) {
    > div {
      max-width: 480px;
      margin: 0 auto;
    }
  }
`

const Content = styled.div`
  width: fit-content;
  text-align: left;
  padding: 25px;
  max-width: 990px;
  margin: 10px auto 0;
  display: inline-block;
  vertical-align: top;

  a {
    text-decoration: none;
  }
  
  @media screen and (max-width: 880px) {
    text-align: center;
    margin-top: -12px;
    
    ${Title} {
      font-size: 28px !important;
    }
    
    ${Subtitle} {
      font-size: 16px !important;
    }
  }
`

const ImageHiderGradient = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  background: rgb(24,24,42);
  background: linear-gradient(0deg, rgba(24,24,42,1) 50%, rgba(24,24,42,0) 100%);
  
  @media screen and (max-width: 880px) {
    position: absolute;
    display: block;
    z-index: 2;
    bottom: -50%;
    height: 100%;
  }
`

const WhitepaperWrapper = styled.div`
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  position: relative;

  img {
    height: 500px;
    margin-bottom: -100px;
  }
  
  @media screen and (max-width: 880px) {
    margin-left: -5%;
    width: 80%;
    
    img {
      width: 100%;
      height: auto;
    }
  }
`

const WhitepaperButton = styled.div`
  width: fit-content;
  padding: 12px 18px;
  border-radius: 5px;
  background: #565A69;
  color: white;
  cursor: pointer;
  margin-top: 20px;
  font-weight: 400;
  
  :hover {
    background: #6C7284;
  }
  
  @media screen and (max-width: 880px) {
    margin: 15px auto 120px;
  }
`

// markup
const WhitepaperSection = () => {

  return (
    <WhitepaperSectionWrapper>
      <div>
        <WhitepaperWrapper>
          <img src={WhitepaperImage} />
          <ImageHiderGradient />
        </WhitepaperWrapper>
        <Content>
          <Title style={{ fontSize: '38px', fontWeight: '400' }}>
            The Dolomite<br />Whitepaper
          </Title>
          <Subtitle style={{ fontSize: '20px' }}>
            Learn how the whole system works.
          </Subtitle>
          <a href={'/Dolomite-v2-Protocol-Whitepaper-v2.0.pdf'} target={'_blank'}>
            <WhitepaperButton>
              Read the Whitepaper
            </WhitepaperButton>
          </a>
        </Content>
      </div>
    </WhitepaperSectionWrapper>
  )
}

export default WhitepaperSection
